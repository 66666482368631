import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();


import * as playVideo from './scripts/play-video';
playVideo.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();


import * as cardTeaserSlider from './scripts/card-teaser-slider';
cardTeaserSlider.init();

import * as imgTeaserSlider from './scripts/img-teaser-slider';
imgTeaserSlider.init();


import * as parallax from "./scripts/parallax";
parallax.init();

import * as lightboxTracking from './scripts/lightbox-tracking';
lightboxTracking.init();

import * as rotateCircle from './scripts/rotate-circle';
rotateCircle.init();

import 'bootstrap/js/dist/tab';

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});